.maxboxxsix {
  background-image: url("../../resources/images/six_bg.png");
  background-repeat: no-repeat; /* 不重复图像 */
  background-size: cover; /* 图像覆盖整个容器 */
  background-position: center; /* 图像在容器中居中 */
  background-attachment: scroll; /* 背景随内容滚动 */
  height: 800px;
}
.maxbox {
  width: 1300px;
  max-width: 1300px;
  margin: 0 auto;
  .box_six {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    .zg_txt_six {
      font-size: 65px;
      color: #ffffff;
      margin-top: 150px;
    }
    .bottom_txt {
      font-size: 22px;
      color: #ffffff;
      margin-top: 50px;
    }
    .bottom_txt2 {
      font-size: 22px;
      color: #ffffff;
    }
    .bt_ljty {
      background: #69acff;
      border-radius: 198px 198px 198px 198px;
      font-size: 22px;
      color: #ffffff;
      padding: 10px 35px;
      margin-top: 43px;
    }
  }
}
.conten_box {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  .title_txt {
    font-size: 44px;
    color: #0e2336;
    margin-top: 120px;
  }
  .title_bottom_icon {
    width: 161px;
    height: 5px;
  }
  .conten_txt_box {
    text-align: center;
    width: 100%;
  }
  .content_txt {
    font-size: 22px;
    color: #616467;
    margin-top: 19px;
  }
  .neirong_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 40px 150px;
  }
  .list_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 472px;
    height: 162px;
    margin-top: 180px;
  }
  .jj1_bottom_box {
    background: #ffffff;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 8px 8px;
    padding: 0 140px 23px 140px;
  }
  .jj2_bottom_box {
    background: #ffffff;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 8px 8px;
    padding: 0 125px 23px 125px;
  }
  .jj3_bottom_box {
    background: #ffffff;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 8px 8px;
    padding: 0 100px 23px 100px;
  }
  .jj1_bg {
    width: 472px;
    height: 306px;
  }
  .jj1 {
    width: 87px;
    height: 87px;
    margin-top: -40px;
  }
  .jj1_txt {
    text-align: center;
    font-size: 28px;
    color: #0e2336;
  }
  .jj2_txt {
    font-size: 15px;
    color: #616467;
  }
  .ljwm_txt {
    font-size: 22px;
    color: #616467;
  }
  .ljwm_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 120px 317px;
  }
  .ljwm_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ljwm_icon {
    width: 150px;
    height: 150px;
  }
  .content_bottom_txt {
    font-size: 28px;
    color: #0e2336;
  }
  .bottom_bottom_txt {
    font-size: 50px;
    color: #0e2336;
  }
  .six_bottom_bg {
    background-image: url("../../resources/images/six_bottom_bg.png");
    background-repeat: no-repeat; /* 不重复图像 */
    background-size: cover; /* 图像覆盖整个容器 */
    background-position: center; /* 图像在容器中居中 */
    background-attachment: scroll; /* 背景随内容滚动 */
    height: 372px;
  }
  .six_bottom_1 {
    font-size: 38px;
    color: #ffffff;
    padding-top: 75px;
  }
  .six_bottom_2 {
    font-size: 20px;
    color: #ffffff;
    margin-top: 19px;
  }
  .six_bottom_3 {
    display: inline-block;
    text-align: center;
    border-radius: 47px 47px 47px 47px;
    border: 2px solid #ffffff;
    padding: 10px 46px;
    font-size: 22px;
    color: #ffffff;
    margin-top: 50px;
  }
  .bg_bg {
    text-align: center;
    margin: 30px auto;
    background-image: url("../../resources/images/bg_bg.png");
    background-repeat: no-repeat; /* 不重复图像 */
    background-size: cover; /* 图像覆盖整个容器 */
    background-position: center; /* 图像在容器中居中 */
    background-attachment: scroll; /* 背景随内容滚动 */
    width: 1500px;
    height: 513px;
  }
}
